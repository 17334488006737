body {
  margin: 0;
  font-family: 'Raleway', sans-serif !important;
  background-color: #000 !important;
  line-height: 1 !important;
}

.rounded-button {
  border-radius: 20px !important;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.white {
  color: #fff;
}

.nomargin {
  margin: 0 !important;
}

.social-icon {
  margin: 20px;
}

.project-gallery {
  background-image: url('./img/BG2.png');
  min-height: 100vh;
}

/** Connect Wallet Button Start */
.ant-dropdown-menu-title-content > button {
  color: white !important;
  background: #1f1f1f !important;
  border: 0 !important;
  box-shadow: none !important;
}

.ant-dropdown-menu-item:hover {
  background: #1f1f1f !important;
}

.mobile-connect-wallet-button > button {
  background-color:#18e0abc9;
  border: 0 !important;
}

/** Connect Wallet Button End */

/** Home Page Start */
.home {
  min-height: 100vh;
  background-image: radial-gradient(300px at 50% 20%,#18E0A9AA 0,hsla(0,0%,100%,0) 100%),radial-gradient(300px at 50% 70%,#7C8DFFAA 0,hsla(0,0%,100%,0) 100%),url('./img/BG2.png');
}

.home-explore-button {
  border-radius: 30px !important;
  font-size: 1.5em !important;
  border: 2px solid !important;
  text-transform: none !important;
}

.home-subtitle {
  font-size: 1.8em;
  margin-top: 30px;
  margin-bottom: 30px;
}

.home-title {
  font-size: 4em;
}

.home-section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.home-card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.home-card {
  background-color: #323232ad;
  border-radius: 10px;
  color: #fff;
  height: 325px;
  width: 250px;
  margin-bottom: 30px;
}

.home-card-icon {
  margin-top: 10px;
}

.home-card-title {
  font-size: 1.3em;
}

.home-card-text {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1em;
  color: rgb(187, 187, 187);
}

/** Home Page End */

/** About Page Start */

.about-section h1 {
  font-size: 3em;
  margin-bottom: 1em;
  margin-top: 1em;
  text-align: center;
}

.about-button-wrapper {
  text-align: center;
}

.about-section-content {
  font-size: 1em;
}

.about-section-content p {
  margin-bottom: 2em;
}

.about-page {
  background-image: url('./img/BG2.png');
}


/** About Page End */

/** Project Gallery Pages Start */
.project-gallery-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.galleryelem:hover {
  opacity: 0.7;
}

.project-gallery-title {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
